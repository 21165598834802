import session, {isStaging} from 'services/SessionService';

export interface QSParams {
  [x: string]: string | number | undefined
}

export default class APIService {
  private apiHost = process.env.NODE_ENV === 'production' ? 'https://api.paramla.com' : 'http://localhost:8000';

  private getHeaders(authHeader?: string) {
    const headers = new Headers()
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    if (isStaging()) {
      headers.append('X-Auth-Role', 'TESTER');
    }

    if (authHeader) {
      headers.append('Authorization', authHeader);
    } else {
      const token = session.getToken();
      if (token !== undefined) {
        headers.append('Authorization', `Basic ${token}`);
      }
    }

    return headers;
  }

  private processRawResponse(rawResponse: Response) {
    if (rawResponse.status === 401) {
      throw new Error('AUTH_REQUIRED')
    }

    if (rawResponse.status === 403) {
      throw new Error('AUTH_REQUIRED')
    }

    const parseResponseJson = () => {
      return rawResponse
        .text()
        .then(data => {
          try {
            if (!data || data.length === 0) {
              return {};
            }
            return JSON.parse(data);
          } catch (e) {
            console.error("Cannot parse API response: ", e)
            return {};
          }
        });
    }

    if (rawResponse.status >= 400) {
      return parseResponseJson()
        .then(json => {
          throw new Error(json.error)
        })
    }

    return parseResponseJson();
  }

  get(url: string, params?: QSParams, authHeader?: string) {
    const paramsArr: Array<string> = [];
    if (params) {
      Object.keys(params).forEach(key => {
        const value = params[key];
        if (value !== undefined && value !== "") {
          paramsArr.push(`${key}=${encodeURIComponent(value)}`)
        }
      })
    }
    const qs = paramsArr.length > 0 ? '?' + paramsArr.join('&') : '';

    return fetch(this.apiHost + url + qs, {
      method: 'GET',
      headers: this.getHeaders(authHeader)
    }).then(this.processRawResponse);
  }

  delete(url: string) {
    return fetch(this.apiHost + url, {
      method: 'DELETE',
      headers: this.getHeaders()
    }).then(this.processRawResponse);
  }

  post(url: string, data?: {}) {
    return fetch(this.apiHost + url, {
      method: 'POST',
      headers: this.getHeaders(),
      body: data === undefined ? null : JSON.stringify(data)
    }).then(this.processRawResponse);
  }

  put(url: string, data?: {}) {
    return fetch(this.apiHost + url, {
      method: 'PUT',
      headers: this.getHeaders(),
      body: data === undefined ? null : JSON.stringify(data)
    }).then(this.processRawResponse);
  }

  download(url: string, filename: string) {
    return fetch(this.apiHost + url, {headers: this.getHeaders()})
      .then(async res => ({
        filename: filename,
        blob: await res.blob()
      }))
      .then(resObj => {
        // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
        const newBlob = new Blob([resObj.blob], {type: 'application/pdf'});

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);

          let link = document.createElement('a');
          link.href = objUrl;
          link.download = resObj.filename;
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        }
      })
  }

  postFile(url: string, file: File) {
    const formData = new FormData();

    formData.append('type', file.type);
    formData.append('file', file);

    const headers = new Headers();
    headers.append('X-Auth-Token', session.getToken() ?? '');

    return fetch(this.apiHost + url, {
      method: 'POST',
      headers: headers,
      body: formData
    }).then(this.processRawResponse);
  }
}
