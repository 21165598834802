import * as React from "react";
import { ButtonModal } from "./FCMMessageTable";
import PaginationTable from "../paginationTable/PaginationTable";
import useAPI from "hooks/API";
import FCMSubscribeDevice from "../page/FCMSubscribeDevice";
import useAPIWithState from "components/Form/Element/OptionElement";
import { Button, Card, Table } from "antd";
import { render } from "react-dom";
import { title } from "process";

export default function FCMAnalyzerTable() {
  const columns = [
    {
      dataIndex: "name",
      title: "Topics",
    },
    {
      dataIndex: "id",
      title: "Analyze",
      render: (id: any) => (
        <Button
          onClick={(ev) => {
            ev.stopPropagation();
            api.post(`/admin/analyze/${id}`).then(() => {
              if (reloadRef?.current) {
                reloadRef?.current();
              }
            });
          }}
        >
          {" "}
          Analyze
        </Button>
      ),
    },
  ];
  const reloadRef = React.useRef<any>();
  const api = useAPI();
  const { data } = useAPIWithState(
    async () => api.get(`/admin/firebaseAnalyzers`),
    reloadRef
  );
  const onSubmit = () => {
    if (reloadRef?.current) {
      reloadRef?.current();
    }
  };
  return (
    <>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender: ({ id,results }: any, ...params: any) => (
            <ExpandedAnalyzerRow results={results} />
          ),
        }}
      />
    </>
  );
}

function ExpandedAnalyzerRow({ results }: any) {
  const resultColumns = [
    {
      dataIndex: "successCount",
      title: "Device",
    },
    {
      dataIndex: "errorCount",
      title: "error",
    },
    { dataIndex: "date", title: "date" },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="expanded-row"
    >
      <Table
        pagination={{}}
        style={{ width: "100%" }}
        columns={resultColumns}
        dataSource={results}
      />
    </div>
  );
}
